import React, { useState } from "react";
import AvatarImage from "../../components/Avatar/avatarImage";
import * as styled from "./buyer.module.css"
import { Row, Col } from "react-bootstrap"
import callsimg from "../../../static/images/PhoneImage.png"
import chatimg from "../../../static/images/ChatImage.png"
// import Progressbar from "../../components/processbar"
import ProgressBar from "@ramonak/react-progress-bar";
// import Chat from "../chat/chat";
// import { Link } from "react-router-dom";
// import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import ChatPopup from "../../components/popup/ChatPopup";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import Loader from "../../components/Loader";
// import Group236 from "../../../static/images/Group 236.png"
// import SharePopup from "../../components/popup/sharePopup";
import memberIcon from "../../../static/images/memberIcon.png"
import * as style1 from "../../pages/dashboard/detail.module.css"
import { Card } from "react-bootstrap";
import { ChatData,formatPrice,getStatusColor, getListingStatus  } from "../../common/GenericFunction";

function BuyerPropectDetails(props) {
    const [openChat, setOpenChat] = useState(false)
    const [loader, setLoader] = useState(false);
    const showChatDetail = () => {
        if (openChat === false) {
            setLoader(true);
        }
        else {
            setLoader(false);

        }
        setOpenChat(p => !p)
    }
    const buyerdetail = props.data
    const [chatAgent, setChatAgent] = useState({})
    const [chatUser, setChatUser] = useState({})
    const [chatId, setChatId] = useState("")
    const userDetails = useSelector(state => state.user.userDetail);
    const chattingUserData = useSelector(state => state.dashboard.chat_disconnect)
    const userlocation = useSelector(state => state.user.userLocation);

    const hanldeImage = (img) => {
        let data = img !== null && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }
    const handleChat = async (id, name, lastName, image) => {
        let names = name
        await chattingUserData !== null && chattingUserData.disconnectUser()
        const apikey = process.env.STREAM_CHAT_APIKEY;

        const chatClient = StreamChat.getInstance(apikey);
        await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
        await chatClient.disconnectUser()

        setChatAgent({ id: id, name: names, image: image })
        setChatUser({ id: userDetails._id, name: userDetails.nam, image: hanldeImage(userDetails.img) })
        setChatId(id + userDetails._id)
        showChatDetail()
    }
    const makeCall = (PhoneNumber) => {
        if (PhoneNumber !== null && PhoneNumber !== "") {
            window.location.href = 'tel://' + PhoneNumber;

        }
    }

    return (
        <div>
            <div className={styled.hunterbg} >
                <Row className={styled.hunterbgName} >
                    <Row
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        <Col lg="6" style={{ textAlign: "right" }}>
                            {buyerdetail.user_id.img ?
                                < img src={process.env.API_IMAGE_URL + buyerdetail.user_id.img} alt="profile" className={styled.cardRowImg} crossOrigin="anonymous" />
                                : <>{buyerdetail.user_id.name ? <div style={{ width: '100px', height: '100px', marginLeft: 'auto', marginRight: "18px" }}><AvatarImage fontSize={'38px'} data={buyerdetail.user_id.name ? buyerdetail.user_id.name : ''} /></div>
                                    : null}</>
                            }
                        </Col>
                        <Col lg="6">
                            {buyerdetail.user_id.is_private_profile === true ?
                                <h6 className="text-capitalize buyerName">{buyerdetail.user_id.nick_name}</h6>
                                :
                                <h6 className="text-capitalize buyerName">{buyerdetail.user_id.name}</h6>
                            }
                            <p>prospect Buyer</p>
                            <div className={styled.callsName}>
                                <span style={{ cursor: "pointer", height: "46px", }}>
                                    <img src={chatimg} alt="chatimg.png" className="chatimg-img" onClick={() => { handleChat(buyerdetail.user_id._id, buyerdetail.user_id.name, buyerdetail.user_id.last_name, buyerdetail.user_id.img ? hanldeImage(buyerdetail.user_id.img) : ""); ChatData("MyBuyer Prospect Chat", userDetails, buyerdetail.user_id._id, userlocation) }} onKeyPress={() => { handleChat(buyerdetail.user_id._id, buyerdetail.user_id.name, buyerdetail.user_id.last_name, buyerdetail.user_id.img ? hanldeImage(buyerdetail.user_id.img) : "") }} aria-hidden />
                                </span>
                                <span style={{ cursor: "pointer", marginLeft: "15px" }} className={styled.tooltip}>
                                    <img onClick={() => makeCall((buyerdetail._id.phone_number !== undefined && buyerdetail._id.phone_number !== null && buyerdetail._id.phone_number !== "") ? buyerdetail._id.phone_number : null)} onKeyPress={() => makeCall((buyerdetail._id.phone_number !== undefined && buyerdetail._id.phone_number !== null && buyerdetail._id.phone_number !== "") ? buyerdetail._id.phone_number : null)} src={callsimg} alt="callsimg.png" className="callsimg-img" aria-hidden />
                                    {buyerdetail.user_id.phone_number !== null ?
                                        <span className={styled.tooltiptext}>{buyerdetail.user_id.phone_number}</span>

                                        : null}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ padding: "0px", width: "100%", margin: '0px' }}>


                        <Col lg="6" className={styled.profilestatusName} id={styled.BuyerProfileStatus}>
                            {" "}
                            Buyer Profile Status{" "}
                        </Col>
                        <Col lg="6" className={styled.profilestatusNo}>
                            {" "}
                            100%{" "}
                        </Col>

                        <Col lg="12" style={{ padding: "0px" }}>
                            <ProgressBar completed={Math.round(buyerdetail.user_id.profile_status ? buyerdetail.user_id.profile_status : 0)} bgColor="#0490fb" height={30}></ProgressBar>
                            {/* <Progressbar
                                bgcolor="rgb(4, 144, 251)"
                                progress={buyerdetail.user_id.profile_status}
                                height={30}
                            /> */}
                        </Col>



                    </Row>



                    <div className={styled.searchCriettext}>
                        <div
                            style={{
                                fontFamily: "DejaVuSansBold",
                            }}
                        >
                            Search Criteria
                        </div>
                        <Row className={styled.searchCriet}>
                            <Col style={{ padding: "0" }}>Area</Col>
                            <Col
                                style={{
                                    fontFamily: "DejaVuSansBold",
                                    textAlign: "right",
                                }}
                            >
                                {buyerdetail.buy_info !== null && buyerdetail.buy_info.area}
                            </Col>
                        </Row>

                        <Row className={styled.searchCriet}>
                            <Col style={{ padding: "0" }}>Budget</Col>
                            <Col
                                style={{
                                    fontFamily: "DejaVuSansBold",
                                    textAlign: "right",
                                }}
                            >${formatPrice(buyerdetail.buy_info !== null && buyerdetail.buy_info.budget)}
                            </Col>
                        </Row>

                        <Row className={styled.searchCriet}>
                            <Col style={{ padding: "0" }}>Prequalified</Col>
                            <Col
                                style={{
                                    fontFamily: "DejaVuSansBold",
                                    textAlign: "right",
                                }}
                            >
                                $ {formatPrice(buyerdetail?.buy_info?.pre_qualified ? buyerdetail.buy_info.pre_qualified : "")}
                            </Col>
                        </Row>
                        <Row className={styled.searchCriet}>
                            <Col style={{ padding: "0" }}>Type</Col>
                            <Col
                                style={{
                                    fontFamily: "DejaVuSansBold",
                                    textAlign: "right",
                                }}
                            >
                                {buyerdetail.buy_info !== null && buyerdetail.buy_info.beds} Bed, {buyerdetail.buy_info !== null && buyerdetail.buy_info.baths} Bath
                            </Col>
                        </Row>
                        {buyerdetail.property_id !== undefined && buyerdetail.property_id !== null &&
                            <div >
                                <div className={styled.imagedetails}>
                                    {buyerdetail.property_id.thumbnail_image !== null && buyerdetail.property_id.thumbnail_image.includes('mls') ? <img src={buyerdetail.property_id.thumbnail_image !== undefined && hanldeImage(buyerdetail.property_id.thumbnail_image)} alt="EditGroup1 " width={200} height={300} className={styled.inthomeImage} id={styled.sellerAvatar} /> :
                                        <img src={buyerdetail.property_id.thumbnail_image !== undefined && hanldeImage(buyerdetail.property_id.thumbnail_image)} width={200} height={300} className={styled.inthomeImage} id={styled.sellerAvatar} alt="EditGroup1 " crossOrigin='anonymous' />}

                                </div>
                                <div className={styled.housedetail}>
                                    <div className="d-flex" style={{ marginLeft: "17px" }}>
                                        <i className="fa fa-circle ClaimActiveIcon " style={{ color: getStatusColor(buyerdetail.property_id.listing_status)}} ></i>
                                        <p className={style1.housetext1}>{getListingStatus(buyerdetail.property_id.listing_status)}</p>
                                        {/* } */}
                                    </div>

                                    <p className={styled.pricehouse} id={styled.priceOfhouse}>${formatPrice(buyerdetail.property_id.price.$numberDecimal)}</p>
                                </div>
                                <div className={styled.headContent} >
                                    <h5 className={styled.intaddess} id={styled.HeadContentAddress}>{buyerdetail.property_id.address.split(',').slice(0).shift()},</h5>
                                    <h5 className={styled.intaddess} id={styled.HeadContentAddress}>{buyerdetail.property_id.address.split(",").slice(1).join(",")}</h5>
                                </div>

                                <div>
                                    <Card
                                        className={styled.detCard}
                                        id={styled.detCards}
                                        style={{
                                            borderRadius: "10px",
                                            height: "93px",
                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            borderTop: "0px"
                                        }}>
                                        <div className="row m-0">
                                            <div className="col-md-4 cardtext2 mt-3">
                                                <p style={{ fontFamily: "DejaVuSansBold" }}>{buyerdetail.property_id.beds}</p>
                                                <p style={{ color: "#898686" }}>Beds</p>
                                            </div>
                                            <div className="col-md-4 cardtext2 mt-3">
                                                <p style={{ fontFamily: "DejaVuSansBold" }}>{buyerdetail.property_id.baths}</p>
                                                <p style={{ color: "#898686" }}>Baths</p>
                                            </div>
                                            <div className="col-md-4 cardtext2 mt-3">
                                                <p style={{ fontFamily: "DejaVuSansBold" }}>{buyerdetail.property_id.square_feet.$numberDecimal}</p>
                                                <p style={{ color: "#898686" }}>Sq. Feet</p>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        }
                        {buyerdetail.message !== "" &&
                            <>
                                <div
                                    style={{
                                        margin: "25px 4px 0px",
                                        fontFamily: "DejaVuSansBold",
                                    }}
                                >
                                    Message
                                </div>
                                <div
                                    style={{ margin: "0 4px", fontSize: "14px" }}
                                >
                                    {buyerdetail.message}
                                </div></>}
                    </div>
                </Row>

            </div>
            <>
                {loader ? <Loader /> : null}
                {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}</>
        </div>
    )
}
export default BuyerPropectDetails